
import { Link } from 'gatsby';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { Title, Twitter, Testonimal } from "../../components/Globals";
import { FaAngleDoubleDown } from "react-icons/fa";
import React, { useState } from 'react';
import Modal from 'react-modal';

const FintechPage = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

    const ref = React.createRef();
  
    const arrowClick = () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      const handleSubmit = (event) => {
        event.preventDefault();
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
        let formData = new FormData();
        formData.append('from', event.target.email.value);
        formData.append('to', 'sandip@cymetrixsoft.com');
        formData.append('subject', 'Downloading FinTech CaseStudy');
        formData.append('text', 'Name: '+event.target.name.value);
        formData.append('text', 'Contact: '+event.target.contact.value);
        fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
          method: 'post',
          headers: headers,
          body: formData,
        })
        .then(function (data) {
          if(data.status === 200) {
         alert("CaseStudy will be opened in other tabs.");
         window.open(require('../../pdfdownload/Cymetrix-CustomerSuccessStory-BFS.pdf')) && window.open(require('../../pdfdownload/Cymetrix-CustomerSuccessStory-BFS-2.pdf'));
    
          }
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });
      } 
      

    return (
      <Layout>
        <div className="fintech-background flex flex-col">
          <Helmet>
            <title>FinTech Consulting | IT Consulting For Fintech | Cymetrix Software</title>
            <meta name="description" content="Cymetrix with its extensive experience in the financial services industry provides custom-made & effective solutions to clients."/>
            <meta name="keywords" content="cymetrix,fintech services, fintech consulting, IT consulting for fintech, Banking, Financial Services & Insurance, BFSI,Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
          </Helmet>
          <h1 className=" xl:text-4xl xl:m-30 md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl text-white text-uppercase text-center">
            FinTech Services
          </h1>
          <h2 className="block text-base xl:text-2xl md:mt-6 lg:text-lg md:text-base sm:text-base sma:text-base text-white text-uppercase text-center">
          Build Your FinTech Platform with us.
          </h2>
          <button class="focus:outline-none " >
            <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
          </button>
        </div>
        <div className="mx-0 flex justify-center mt-6">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3" style={{backgroundColor: '#f6f6f6'}} ref={ref}>
            <div class="text-gray-700 pb-5">
                <h1 className="text-lg  px-5 font-bold uppercase mt-5 mb-3" style={{color: '#1e407b'}}>FinTech Services</h1>
                
                <p className="mt-4 px-5 text-justify">The technology of finance is evolving rapidly and it is getting ever simpler for new suppliers to come into the market and deliver a range of banking and financial services. This changes the conduct of customers and how they use these services. New technological progress and support for innovation from governments is enabling us to open new FinTech markets which were not serviceable before.</p>

                <p className="mt-4 px-5 text-justify">In order to encourage financial inclusion, Cymetrix Software - a fintech software development company in USA empowers financial companies to complete their journey of digital transformation. Virtual banking, business lending, and customer lending proposals are uniquely offered across a single converged framework based on a fully modular architecture and microservices.</p>

                <p className="mt-4 px-5 text-justify">It's the ideal opportunity for your lending to move at the speed of business. Here are the means of how we can make it happen.</p>
                <p className="mt-4 px-5 text-justify">Develop your lending services to create a more efficient, flexible, and responsive business.</p>

                <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4 my-4 ">
                  <li>INCREASE REVENUE AND SCALABILITY GROWTH</li>
                  <li>IMPROVE MARGINS AND OPERATIONAL EFFICIENCIES</li> 
                </ul>

                <h1 className="text-lg  px-5 font-bold uppercase my-3" style={{color: '#1e407b'}}>SALESFORCE IMPLEMENTATION SERVICES PROVING QUITE BENEFICIAL TO FINTECH FIRMS</h1>

                <p className="mt-4 px-5 text-justify">In cloud technology, Salesforce is at #1, and the whole Salesforce force.com framework is progressing towards cloud innovation nowadays. For FinTech firms, client relationships are given massive priority, Salesforce, definitely, has plenty of advantages.</p>

                <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4 my-4 ">
                  <li>Sales rises as expanded conversion take place for leads</li>
                  <li>It helps to develop a customer business model at the core</li>
                  <li>It is useful to point the marketing process in the right direction.</li>
                  <li>The complete customer engagement process becomes easy</li>
                  <li>Rapid development of lending or banking services helps to bring new services quickly in to market</li>
                </ul>

                <h1 className="text-lg  px-5 font-bold uppercase my-3" style={{color: '#1e407b'}}>Cymetrix Software is a FinTech expert</h1>
                <p className="mt-4 px-5 text-justify">Cymetrix Software - Best FinTech company provides custom fintech software engineering solutions and platform development services to help you grow your business. Fintech or Financial technology interprets the potential of data to be monetized. We provide salesforce offshore support and give extensive consulting services, software engineering along with the key technology solutions to gear up for fintech software development for consumer-oriented services in various industry areas.</p>
            </div>
          </div>
        </div>
        <div className="section-title text-center m-8">
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>Our Work</span>
        </div>
        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Challenge</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">The customer's point was to present the opportunity of making new budgetary products with the help of outer sellers. The target audience was financial organizations. By offering group accounts to handle cash collected for shared tasks, the client had a strategy to extend the normal features of the bank account.</div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Solution</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">
                    <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4 my-4 ">
                      <li>In the field of right licenses and structural solutions, Salesforce consulting.</li>
                      <li>Salesforce Backend and mobile frontend development for the POC usage of an SFDC based layer between the portable mobile or web application and the Bank Open API.</li>
                      <li>Creation of virtual bank accounts to raise money for their particular aims and intentions from groups of people.</li>
                      <li>Providing virtual differentiation in personal accounts between the money of the users and virtual community accounts.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Result</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">We have guided our client to demonstrate a known opportunity for non-banking companies to succeed in the financial industry and develop banking solutions through the use of financial firms’ information.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       <div className="flex flex-row items-center justify-center  mt-4 sm:ml-10">
         
             
<button  className="uppercase font-bold px-8 lg:px-10 py-3 rounded bg-gray-500 text-gray-100 hover:bg-hover focus:shadow-outline focus:outline-none transition duration-300" onClick={() => setModalIsOpen(true)}>Download CaseStudy</button>

<Modal  isOpen={modalIsOpen} onRequestClose={()=> setModalIsOpen(false)}

  style={{

    overlay: {
      overflow:'auto',
      position: 'fixed',
      top: 50,
      left: 300,
      right: 300,
      bottom: -20,
      
    
    }
    
  }}
  
>
<h1 className="text-lg  px-5 font-bold uppercase text-center" style={{color: '#1e407b'}}>Enter Details To Download CaseStudy</h1>
<main className="bg-transparent w-full flex flex-col items-center">
        <div className="max-w-5xl mx-4 ">
        <form className="justify-center flex" onSubmit={handleSubmit}>
                <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
                
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                   
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                        Enter Your Email Id*
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="email" type="text" placeholder="" name="email" required/>
                    </div>
                </div>
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                        Enter Your Name*
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="name" type="text" placeholder="" name="name" required/>
                    </div>
                </div>
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="contact">
                        Enter Your Contact No.
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="contact" type="text" placeholder="" name="contact" />
                    </div>
                </div>
                <div className="-mx-3 md:flex">
                    <div className="sma:w-full">
                    
                   <button  className="sma:w-full bg-header text-white font-bold py-2 px-4 mb-3 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit" > Submit</button>

                   <button className=" sma:w-full bg-transparent text-center hover:border-blue-500 text-black text-base font-bold w-24 py-2 px-2 border border-gray-500 hover:border-transparent uppercase rounded-lg" onClick={() => setModalIsOpen(false)} >Close</button>
                    </div>
                </div>
                </div>
            </form>
        </div>
    </main>
   
</Modal>

          </div>
        <Testonimal />
        <div className="lg:mt-10 mt-5">
          <Twitter />
        </div>
      </Layout>
    );
}

export default FintechPage;